<template>
  <div>
    <div class="detailContent">
      <!-- 应用信息 -->
      <p class="detailTitle">{{$t('SecurityMonitor.Sensitivebehavior.AppInfo')}}</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">{{$t('public.ApplicationName')+'：'}}</span>
            <span class="detaildesc">{{ detailData.name }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('public.ApplicationVersion')+'：'}}</span>
            <span class="detaildesc">{{ detailData.version }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.SdkVersion')+'：'}}</span>
            <span class="detaildesc">{{ detailData.sdkVersion }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">{{$t('SecurityMonitor.Sensitivebehavior.AppPackageName')+'：'}}</span>
            <span class="detaildesc">{{ detailData.packagename }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="vertical-align: top;">{{$t('SecurityMonitor.Sensitivebehavior.AppMD5')+'：'}}</span>
            <span class="detaildesc" style="vertical-align: top;">{{ detailData.appMd5 }}</span>
          </div>
        </div>
      </div>
      <!-- <el-descriptions :title="$t('SecurityMonitor.Sensitivebehavior.AppInfo')" :column="2">
        <el-descriptions-item :label="$t('public.ApplicationName')">{{ detailData.name }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.AppPackageName')">{{ detailData.packagename }}</el-descriptions-item>
        <el-descriptions-item :label="$t('public.ApplicationVersion')">{{ detailData.version }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.AppMD5')" >{{ detailData.appMd5 }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.SdkVersion')">{{ detailData.sdkVersion }}</el-descriptions-item>
      </el-descriptions> -->




      <!-- 基本信息 -->
      <el-descriptions :title="$t('SecurityMonitor.Sensitivebehavior.BasicInfo')" :column="2">
        <el-descriptions-item :label="$t('public.DeviceName')">{{ detailData.devicename }}</el-descriptions-item>
        <el-descriptions-item :label="$t('public.Name')">{{ detailData.username }}</el-descriptions-item>

        <!-- <el-descriptions-item label="设备厂商">{{ detailData.operator }}</el-descriptions-item>
        <el-descriptions-item label="合规状态">{{ detailData.isDanger }}</el-descriptions-item> -->
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.EquipmentModel')">{{ detailData.model }}</el-descriptions-item>
        <el-descriptions-item :label="$t('public.SystemVersion')">{{ detailData.osVersion }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.DeviceIP')">{{ detailData.ip }}</el-descriptions-item>
        <el-descriptions-item :label="$t('public.Username')">{{ detailData.loginname }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.UserDepartment')">{{  detailData.groupfullname.substr(groupFullNameShowByIndex) }}</el-descriptions-item>
        
          <!-- 2024.7.10wjw说产品要设备IMEI根据设备类型展示；android: 设备IMEI; iOS: UDID;Linux:网卡Mac; Windows:网卡Mac -->
       <el-descriptions-item v-if="$common.getSystemName(detailData.osVersion)=='Android'" :label="$t('public.IMEIAndroid')">{{ detailData.imei }}</el-descriptions-item>
       <el-descriptions-item v-if="$common.getSystemName(detailData.osVersion)=='iOS'"  :label="$t('public.IMEIiOS')">{{ detailData.imei }}</el-descriptions-item>
       <el-descriptions-item v-if="$common.getSystemName(detailData.osVersion)=='Linux'"  :label="$t('public.IMEIMac')">{{ detailData.imei }}</el-descriptions-item>
       <el-descriptions-item  v-if="$common.getSystemName(detailData.osVersion)=='Windows'" :label="$t('public.IMEIMac')">{{ detailData.imei }}</el-descriptions-item>
       <el-descriptions-item v-if="$common.getSystemName(detailData.osVersion)=='Unknown'" :label="$t('public.IMEIUnknown')">{{ detailData.imei }}</el-descriptions-item>
        
        <!-- <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.EquipmentIMEI')">{{ detailData.imei }}</el-descriptions-item> -->
        <el-descriptions-item :label="$t('public.InternetMethod')">{{ $filter.surfInterType(detailData.netType) }}</el-descriptions-item>

      </el-descriptions>

      <!-- <p class="detailTitle">基本信息</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">设备名称：</span>
            <span class="detaildesc">{{ detailData.devicename }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">姓名：</span>
            <span class="detaildesc">{{ detailData.username }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">设备型号：</span>
            <span class="detaildesc">{{ detailData.model }}</span>
          </div>
        /**** <div class="group">
            <span class="detailLabel">合规状态：</span>
            <span class="detaildesc"></span>
          </div> ***/
          <div class="group">
            <span class="detailLabel">系统版本：</span>
            <span class="detaildesc">{{ detailData.osVersion }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">设备IP：</span>
            <span class="detaildesc">{{ detailData.ip }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">用户名：</span>
            <span class="detaildesc">{{ detailData.loginname }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">用户部门：</span>
            <span class="detaildesc">{{ detailData.groupfullname }}</span>
          </div>
        /*** */ <div class="group">
            <span class="detailLabel">设备型号：</span>
            <span class="detaildesc">{{ detailData.model }}</span>
          </div> ***/
    /*** */<div class="group">
            <span class="detailLabel">设备越狱：</span>
            <span class="detaildesc">{{detailData.infoType}}</span>
          </div> ***/
      <div class="group">
        <span class="detailLabel" style="display: block; float: left; ">设备IMEI：</span>
        <span class="detaildesc" style="display: block; float: left; text-align: justify">{{ detailData.imei }}</span>
      </div>
      <div class="group">
        <span class="detailLabel" style="display: block; float: left;">上网方式：</span>
        <span class="detaildesc" style="display: block; float: left; text-align: justify">{{ detailData.netType }}</span>
      </div>
    </div>
  </div> -->
      <!-- 位置信息 -->
      <el-descriptions :title="$t('SecurityMonitor.Sensitivebehavior.PositionInfor')" :column="2">
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.Country')">{{ detailData.country }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.Province')">{{ detailData.province }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.City')">{{ detailData.city }}</el-descriptions-item>
        <el-descriptions-item :label="$t('public.LatitudeLongitude')" v-if="detailData.longitude == ''"></el-descriptions-item>
        <el-descriptions-item :label="$t('public.LatitudeLongitude')" v-else>{{ detailData.longitude }},{{ detailData.latitude }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.Address')">{{ detailData.location }}</el-descriptions-item>
      </el-descriptions>
      <!-- <p class="detailTitle">位置信息</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">国家：</span>
            <span class="detaildesc">{{ detailData.country }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">省份：</span>
            <span class="detaildesc">{{ detailData.province }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">城市：</span>
            <span class="detaildesc">{{ detailData.city }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">经纬度：</span>
            <span class="detaildesc" v-if="detailData.longitude == ''"></span>
            <span class="detaildesc" v-else>{{ detailData.longitude }},{{ detailData.latitude }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group">
          <span class="detailLabel">详细地址：</span>
          <span class="detaildesc">{{ detailData.location }}</span>
        </div>
      </div> -->
      <!-- 风险信息 -->
      <el-descriptions :title="$t('SecurityMonitor.Sensitivebehavior.RiskInfo')" :column="2">
        <el-descriptions-item :label="$t('public.BehaviorType')">{{ detailData.infoType }}</el-descriptions-item>
        <el-descriptions-item :label="$t('public.Behaviorlevel')">
          <span class="detaildesc" v-if="detailData.level == '3'" style="color: #d9001b; cursor: auto">{{$t('SecurityMonitor.Sensitivebehavior.High')}}</span>
          <span class="detaildesc" v-if="detailData.level == '2'" style="color: #f59a23; cursor: auto">{{$t('SecurityMonitor.Sensitivebehavior.Middle')}}</span>
          <span class="detaildesc" v-if="detailData.level == '1'" style="color: #00cdcd; cursor: auto">{{$t('SecurityMonitor.Sensitivebehavior.Low')}}</span>
        </el-descriptions-item>
        <!--  产生时间  -->
        <el-descriptions-item :label="$t('public.GenerationTime')">{{  detailData.time }}</el-descriptions-item>
        <!-- 上报时间   -->
        <el-descriptions-item :label="$t('public.ReportingTime')">{{detailData.createtime }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.ResponseAction')" :span="2">{{ detailData.rule }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.Sensitivebehavior.JudgmentSteps')" :span="2" :contentStyle="{'display': 'block'}">
          <div v-for="(e, i) in detailData.decideStr" :key="i"> {{ i + 1 }}、{{ e }}</div>
        </el-descriptions-item>
      </el-descriptions>
      <!-- <p class="detailTitle">风险信息</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">行为类型：</span>
            <span class="detaildesc">{{ detailData.infoType }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">产生时间：</span>
            <span class="detaildesc">{{
              detailData.createtime | dateformat("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">响应动作：</span>
            <span class="detaildesc">{{ detailData.rule }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">行为级别：</span>
            <span class="detaildesc" v-if="detailData.level == '3'" style="color: #d9001b; cursor: auto">高</span>
            <span class="detaildesc" v-if="detailData.level == '2'" style="color: #f59a23; cursor: auto">中</span>
            <span class="detaildesc" v-if="detailData.level == '1'" style="color: #00cdcd; cursor: auto">低</span>
          </div>
          <div class="group">
            <span class="detailLabel">上报时间：</span>
            <span class="detaildesc">{{
              detailData.time | dateformat("YYYY-MM-DD HH:mm:ss")
            }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group" style="width: 100%">
          <div class="detailLabel" style="
               float: left;
               height: 24px;
               line-height: 24px;
               text-align: left;
               pading-right: 5px;

              ">
            <span style="width: 13px">研判步骤：</span>
          </div>
          <div style="
                float: left;
                width: 78%;
                height: inherit;
                text-align: left;
                padding-right: 5px;
              " class="detaildesc">
            <p v-for="(e, i) in detailData.decideStr" :key="i" style="
                  width: 100%;
                  display: inline-block;
                  overflow: hidden;
                  color: #807e7e;
                  margin-left: 0;
                  line-height: 24px;
                  word-wrap: break-word;
                  word-break: break-all;
                ">
              {{ i + 1 }}、{{ e }}
            </p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detailData: {
      type: Object,
      default: {},
   
    }
  },

  data() {
    return {   groupFullNameShowByIndex: ''}
  },

  created() {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
  },
  methods: {}
}
</script>

<style lang='scss' scoped>
.detailContent {
  width: 100%;
  // height: 469px !important;
  // overflow: auto !important;
  .detailTitle {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
  }
  .detailbox {
    width: 100%;
    display: flex;
    font-size: 12px;
    .boxLeft {
      width: 50%;
      margin-left: 68px;
      line-height: 24px;
      .group {
        margin-top: 8px;
        .detailLabel {
          width: 44%;
          text-align: left;
        }
        .detaildesc {
          width: 56%;
        }
      }
    }
    .boxRight {
      width: 50%;
      line-height: 24px;
      .group {
        margin-top: 8px;
        .detailLabel {
          width: 23%;
          text-align: left;
        }
        .detaildesc {
          width: 67%;
          display: inline-block;
          overflow-y: visible;
        }
      }
    }
  }
  .boxBot {
    width: 100%;
    padding-left: 68px;
    font-size: 12px;
    .group {
      margin-top: 8px;
      .detailLabel {
        width: 22%;
        text-align: left;
        padding-right: 5px;
      }
      .detaildesc {
        width: 78%;
        display: inline-block;
      }
    }
  }
}
::v-deep .el-descriptions__title {
  color: #606266;
  font-size: 14px;
}
::v-deep .el-descriptions__body {
  color: #606266;
  font-size: 12px;
  background-color: #fff;
  padding-left: 70px;
}
.version-label {
  background: #b787df;
}
.version-content {
  background: #d182e0;
}
::v-deep .el-descriptions__title {
    font-size: 14px;
    font-weight: 700;
}
</style>